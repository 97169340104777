<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ Action === 'Edit' ? $t('Navigation.Products/SinglePageEdit') : $t('Navigation.Products/SinglePageAdd') }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              <CButton v-if="!$store.state.user.permission.MutipleLocale" color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Product/SinglePage.Basic')" active>
                <CRow>
                  <CCol md="7">
                    <CRow v-if="Action === 'Edit'" form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.URL') }}
                      </CCol>
                      <CCol sm="9" class="d-flex align-items-center">
                        <a v-if="Data.Slug" class="text-info mb-0 mr-2" :href="`https://${$store.state.user.permission.Domain}/landing/${Data.Slug}`" target="_blank">
                          https://{{ $store.state.user.permission.Domain }}/landing/{{ Data.Slug }}
                        </a>
                        <a v-if="Data.Slug" href="#" v-c-tooltip="{content: $t('Product/SinglePage.CopyURL'), placement: 'top'}" @click="CopyToClipboard(`https://${$store.state.user.permission.Domain}/landing/${Data.Slug}`)" class="text-dark">
                          <i class="far fa-copy" />
                        </a>
                      </CCol>
                    </CRow>
                    <CRow v-if="Action === 'Edit'" form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.Feed') }}
                      </CCol>
                      <CCol sm="9" class="d-flex align-items-center">
                        <a v-if="Data.Slug" class="text-info mb-0 mr-2" :href="`https://${$store.state.user.permission.Domain}/product/feed/landing/${Data.Slug}`" target="_blank">
                          https://{{ $store.state.user.permission.Domain }}/product/feed/landing/{{ Data.Slug }}
                        </a>
                        <a v-if="Data.Slug" href="#" v-c-tooltip="{content: $t('Product/SinglePage.CopyURL'), placement: 'top'}" @click="CopyToClipboard(`https://${$store.state.user.permission.Domain}/landing/${Data.Slug}`)" class="text-dark">
                          <i class="far fa-copy" />
                        </a>
                      </CCol>
                    </CRow>
                    <CInput
                        :label="$t('Product/SinglePage.Slug')"
                        v-model="Data.Slug"
                        horizontal
                    />
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                          :label="$t('Product/SinglePage.Name')"
                          v-model="Data.Name"
                          horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                          :label="$t('Product/SinglePage.Name')"
                          v-model="Data.LanguageData[NowChooseLanguage].Name"
                          horizontal
                      />
                    </template>
                    <CInput
                      v-model="Data.Variable.OriginTag"
                      horizontal
                    >
                      <template #label>
                        <label class="col-form-label col-sm-3">
                          {{ $t('Product/SinglePage.OriginTag') }} <i v-c-tooltip="{content: $t('Product/SinglePage.OriginTagDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                        </label>
                      </template>
                    </CInput>
                    <CInput
                      :label="$t('Product/SinglePage.LIFF')"
                      v-model="Data.Variable.LIFF"
                      description="範例: 2000208118-bBKBk6xm"
                      horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.EnableCustomTracking') }} <i v-c-tooltip="{content: $t('Product/SinglePage.EnableCustomTrackingDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="Data.Variable.EnableCustomTracking"/>
                      </CCol>
                    </CRow>
                    <template v-if="Data.Variable.EnableCustomTracking === true">
                      <CInput
                        :label="$t('Product/SinglePage.GTM')"
                        v-model="Data.Variable.GTM"
                        description="範例: GTM-XXXXXXX"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/SinglePage.FP')"
                        v-model="Data.Variable.FP"
                        description="範例: 1643456104728931"
                        horizontal
                      />
                    </template>
                  </CCol>
                  <CCol md="5">
                    <CRow>
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.StartTime') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.Variable.StartTime" mode="dateTime" is24hr :max-date="$dayjs(Data.Variable.EndTime || new Date).toISOString()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.EndTime') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.Variable.EndTime" mode="dateTime" is24hr :min-date="$dayjs(Data.Variable.StartTime || new Date).toISOString()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Post/Detail.Status') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="PostStatus"/>
                      </CCol>
                    </CRow>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.EnableDeadline') }} <i v-c-tooltip="{content: $t('Product/SinglePage.DeadlineDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="Data.Variable.EnableDeadline"/>
                      </CCol>
                    </CRow>
                    <CRow v-if="Data.Variable.EnableDeadline === true">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/SinglePage.Deadline') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.Variable.Deadline" mode="dateTime" is24hr :min-date="$dayjs().toISOString()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/SinglePage.Images')">
                <h5>{{ $t('Product/SinglePage.Cover') }}</h5>
                <hr>
                <CRow class="no-gutters mx-n1 mb-3">
                  <template v-if="NowChooseLanguage === 'default'">
                    <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                  <template v-else>
                    <CCol v-if="Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.LanguageData[NowChooseLanguage].Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                </CRow>
                <h5>
                  {{ $t('Post/Detail.Carousel') }}
                </h5>
                <hr>
                <template v-if="NowChooseLanguage === 'default'">
                  <Draggable
                      v-model="Data.Carousel"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <template v-else>
                  <Draggable
                      v-model="Data.LanguageData[NowChooseLanguage].Carousel"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.LanguageData[NowChooseLanguage].Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
              </CTab>
              <CTab :title="$t('Product/SinglePage.Content')">
                <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="CurrentContent" />
              </CTab>
              <CTab :title="$t('Post/Detail.SEO')">
                <CRow>
                  <CCol md="8">
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.SEO.Title"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/Keyword')"
                        v-model="Data.SEO.Keywords"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/Description')"
                        v-model="Data.SEO.Description"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.SEO.OGImage"
                        horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Title"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/Keyword')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Keywords"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Description"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGImage"
                        horizontal
                      />
                    </template>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/SinglePage.ProductList')">
                <CAlert color="info">
                  拖曳單列可交換商品排序。
                </CAlert>
                <div class="position-relative table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th v-for="Field in SalePageProductField" scope="col" :key="Field.key">
                        {{ Field.label }}
                      </th>
                    </tr>
                    </thead>
                    <draggable v-model="Data.Variable.ProductList" tag="tbody">
                      <tr v-for="(Item, Index) in Data.Variable.ProductList" :key="Item._id">
                        <td v-for="Field in SalePageProductField" :key="Field.key">
                          <template v-if="Field.key === 'Cover'">
                            <img v-lazy="Item[Field.key]" width="40" height="40" class="img-fluid" />
                          </template>
                          <template v-else-if="Field.key === 'EnableMemberAmount'">
                            <CSwitch color="success" size="sm" :checked.sync="Item[Field.key]"/>
                          </template>
                          <template v-else-if="Field.key === 'Action'">
                            <CButton color="danger" type="button" size="sm" @click="RemoveChooseProduct(Index)">
                              <CIcon name="cil-trash" class="c-icon-custom-size" />
                            </CButton>
                          </template>
                          <template v-else>
                            {{ Item[Field.key] }}
                          </template>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
                <hr>
                <CButton color="success" type="button" @click="OpenProductListModel">
                  {{ $t('Video/Detail.ChooseProduct') }}
                </CButton>
              </CTab>
              <CTab :title="$t('Product/SinglePage.PaymentLogistic')">
                <h5 class="mb-3 d-flex justify-content-between align-items-center">
                  {{ $t('Product/SinglePage.PaymentWay') }}
                  <CButton color="info" type="button" size="sm" @click="OpenPaymentListModel">
                    {{ $t('Global.Choose') + $t('Product/SinglePage.PaymentWay') }}
                  </CButton>
                </h5>
                <CDataTable
                  id="PaymentList"
                  :loading="Loading"
                  :items="Data.Variable.PaymentList"
                  :fields="PaymentField"
                  responsive
                  hover
                >
                  <template #No="{index}">
                    <td class="text-center">
                      {{ index + 1}}
                    </td>
                  </template>
                  <template #Status="{item}">
                    <td>
                      <CBadge :color="(item.Status === true ? 'success' : 'danger')">
                        {{ $t('Payment.StatusType.' + item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #System="{item}">
                    <td>
                      {{ $t('Payment.PaymentSystem.' + item.System) }}
                    </td>
                  </template>
                  <template #Method="{item}">
                    <td>
                      {{ $t('Payment.PaymentMethods.' + item.Method) }}
                    </td>
                  </template>
                  <template #Action="{index}">
                    <td>
                      <CButton color="danger" type="button" size="sm" @click="Data.Variable.PaymentList.splice(index, 1)">
                        <CIcon name="cil-trash" class="c-icon-custom-size" />
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
                <hr>
                <h5 class="mb-3 d-flex justify-content-between align-items-center">
                  {{ $t('Product/SinglePage.ShipmentWay') }}
                  <CButton color="info" type="button" size="sm" @click="OpenLogisticsListModel">
                    {{ $t('Global.Choose') + $t('Product/SinglePage.ShipmentWay') }}
                  </CButton>
                </h5>
                <CDataTable
                  id="LogisticsList"
                  :loading="Loading"
                  :items="Data.Variable.LogisticsList"
                  :fields="LogisticsField"
                  responsive
                  hover
                >
                  <template #No="{index}">
                    <td class="text-center">
                      {{ index + 1}}
                    </td>
                  </template>
                  <template #System="{item}">
                    <td>
                      {{ $t('Logistics.LogisticsSystem.' + item.System) }}
                    </td>
                  </template>
                  <template #Method="{item}">
                    <td>
                      {{ $t('Logistics.LogisticsMethods.' + item.Method) }}
                    </td>
                  </template>
                  <template #Action="{index}">
                    <td>
                      <CButton color="danger" type="button" size="sm" @click="Data.Variable.LogisticsList.splice(index, 1)">
                        <CIcon name="cil-trash" class="c-icon-custom-size" />
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CTab>
              <CTab :title="$t('Product/SinglePage.Offer')">
                <h5>
                  {{ $t('Product/SinglePage.EnableOffer') }}
                </h5>
                <hr>
                <label v-for="Item in EnableOfferOptions" :key="Item.value" :for="Item.value + '-Enable'" class="mr-2">
                  <input type="checkbox" :id="Item.value + '-Enable'" name="EnableOffer" :value="Item.value"
                         v-model="Data.Variable.EnableOffer"><span class="ml-2">{{ Item.label }}</span>
                </label>
              </CTab>
              <CTab v-if="Features.includes('Commission')" :title="$t('Product/SinglePage.OwnerCommission')">
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    {{ $t('Product/SinglePage.Owner') }}
                  </CCol>
                  <CCol sm="9">
                    <multiselect
                      v-model="Data.Variable.Owner"
                      :options="SearchAccountData"
                      :optionHeight="34"
                      label="Account"
                      track-by="UserID"
                      :options-limit="1000"
                      :limit="3"
                      :searchable="true"
                      :loading="OwnerSearchLoading"
                      @search-change="SearchAccount"
                      :placeholder="$t('Global.PleaseSelect')"
                      :selectLabel="$t('Global.SelectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                    >
                      <template #noOptions>
                        {{ $t('Product/SinglePage.OwnerSearch') }}
                      </template>
                      <template #noResult>
                        {{ $t('Global.NoOptions') }}
                      </template>
                    </multiselect>
                  </CCol>
                </CRow>
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    {{ $t('Product/SinglePage.CommissionPercentage') }}
                  </CCol>
                  <CCol sm="9">
                    <CInput v-model="Data.Variable.CommissionPercentage" min="0.00" step="0.01" :placeholder="$t('Product/SinglePage.CommissionPercentage')" type="number" />
                  </CCol>
                </CRow>
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    {{ $t('Product/SinglePage.PaymentFeePercentage') }}
                  </CCol>
                  <CCol sm="9">
                    <template v-if="Array.isArray(Data.Variable.PaymentFeePercentage) && Data.Variable.PaymentFeePercentage.length > 0">
                      <CRow>
                        <CCol col="4">{{ $t('Product/SinglePage.PaymentWay') }}</CCol>
                        <CCol col="3">{{ $t('Product/SinglePage.CalculateType') }}</CCol>
                        <CCol col="3">{{ $t('Product/SinglePage.Value') }}</CCol>
                        <CCol />
                      </CRow>
                      <hr class="my-2">
                      <CRow v-for="(FeeSettingData, Index) in Data.Variable.PaymentFeePercentage" :key="'PaymentFeePercentage' + Index">
                        <CCol col="4">
                          <CSelect :options="PaymentListOptions" size="sm" v-model="FeeSettingData.ID" :value.sync="FeeSettingData.ID" :placeholder="$t('Global.PleaseSelect')" @change="ChangeFeePercentage('PaymentFeePercentage', Index)" />
                        </CCol>
                        <CCol col="3">
                          <CSelect :options="CalculateTypeOptions" size="sm" v-model="FeeSettingData.Type" :value.sync="FeeSettingData.Type" :placeholder="$t('Global.PleaseSelect')" />
                        </CCol>
                        <CCol col="3">
                          <CInput v-model="FeeSettingData.Value" size="sm" min="0.00" step="0.01" :placeholder="$t('Payment.Fee')" type="number" />
                        </CCol>
                        <CCol class="text-right">
                          <CButton color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="RemoveFeePercentage('PaymentFeePercentage', Index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size"/>
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                    <CButton size="sm" @click="AddFeePercentage('PaymentFeePercentage')" color="info">
                      <CIcon name="cil-plus" class="mr-1"/>{{ $t('Global.Add') }}
                    </CButton>
                  </CCol>
                </CRow>
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    {{ $t('Product/SinglePage.ShipmentFeePercentage') }}
                  </CCol>
                  <CCol sm="9">
                    <template v-if="Array.isArray(Data.Variable.ShipmentFeePercentage) && Data.Variable.ShipmentFeePercentage.length > 0">
                      <CRow>
                        <CCol col="4">{{ $t('Product/SinglePage.ShipmentWay') }}</CCol>
                        <CCol col="3">{{ $t('Product/SinglePage.CalculateType') }}</CCol>
                        <CCol col="3">{{ $t('Product/SinglePage.Value') }}</CCol>
                        <CCol />
                      </CRow>
                      <hr class="my-2">
                      <CRow v-for="(FeeSettingData, Index) in Data.Variable.ShipmentFeePercentage" :key="'ShipmentFeePercentage' + Index">
                        <CCol col="4">
                          <CSelect :options="LogisticsListOptions" size="sm" v-model="FeeSettingData.ID" :value.sync="FeeSettingData.ID" :placeholder="$t('Global.PleaseSelect')" @change="ChangeFeePercentage('ShipmentFeePercentage', Index)" />
                        </CCol>
                        <CCol col="3">
                          <CSelect :options="CalculateTypeOptions" size="sm" v-model="FeeSettingData.Type" :value.sync="FeeSettingData.Type" :placeholder="$t('Global.PleaseSelect')" />
                        </CCol>
                        <CCol col="3">
                          <CInput v-model="FeeSettingData.Value" min="0.00" step="0.01" size="sm" :placeholder="$t('Logistics.Fee')" type="number" />
                        </CCol>
                        <CCol class="text-right">
                          <CButton color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="RemoveFeePercentage('ShipmentFeePercentage', Index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size"/>
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                    <CButton size="sm" @click="AddFeePercentage('ShipmentFeePercentage')" color="info">
                      <CIcon name="cil-plus" class="mr-1"/>{{ $t('Global.Add') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <input id="tinymce-image-input" type="text" class="d-none">
    <component :is="ProductListModelComponent" :Toggle="ProductListModel" :ChooseSaleMode="ChooseSaleMode" @ChooseProduct="ChooseProduct" @ChoosePayment="ChoosePayment" @ChooseLogistics="ChooseLogistics" />
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯銷售頁"
  }
}
</route>

<script>
import DataSchema from '@/schema/post/detail'
// import LanguageDataSchema from '@/schema/post/languageData'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'SalePage',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Draggable: () => import('vuedraggable'),
    Editor
  },
  data () {
    return {
      Action: 'Edit',
      Data: {}, //主資料

      OldSlug: '',
      PostStatus: false, //格式化後的狀態開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      SearchAccountData: [],
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },

      ProductListModelComponent: null,
      ProductListModel: false,
      ChooseSaleMode: ['SalePageOnly', 'Default', 'PreOrder'],

      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      OwnerSearchLoading: false,
      TempAccount: '',
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      PaymentList: [],
      PaymentListOptions: [],
      LogisticsList: [],
      LogisticsListOptions: [],
      EnableOffer: [],
      UTMAccordion: 0,
      Features: []
    }
  },
  computed: {
    // FilterPaymentListOptions () {
    //   console.log(this.Data.Variable.PaymentFeePercentage.map(value => value.ID))
    //   return this.PaymentListOptions.filter(item => !this.Data.Variable.PaymentFeePercentage.map(value => value.ID).includes(item.value))
    // },
    // FilterLogisticsListOptions () {
    //   console.log(this.Data.Variable.ShipmentFeePercentage.map(value => value.ID))
    //   return this.LogisticsListOptions.filter(item => !this.Data.Variable.ShipmentFeePercentage.map(value => value.ID).includes(item.value))
    // },
    CampaignSourceOptions () {
      return ['facebook', 'google', 'blog', 'instagram', 'line', 'sms', 'email', 'youtube', 'web', 'services', 'custom']
    },
    CampaignMediumOptions () {
      return ['ads', 'post', 'post-ads', 'promotion', 'system', 'chat', 'gdn', 'custom']
    },
    CurrentContent: {
      get () {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    return this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content
                  }
              }
            }
            return this.Data.LanguageData[this.NowChooseLanguage].Content
          }
        }
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Content
              }
          }
        }
        return this.Data.Content
      },
      set (value) {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content = value
                  }
              }
            } else {
              this.Data.LanguageData[this.NowChooseLanguage].Content = value
            }
          }
        } else {
          if (this.EnableResponseContent) {
            switch (this.NowChooseDevice) {
              case 'Web':
              case 'Mobile':
                if (this.Data.ResponseData[this.NowChooseDevice]) {
                  this.Data.ResponseData[this.NowChooseDevice].Content = value
                }
            }
          } else {
            this.Data.Content = value
          }
        }
      }
    },
    SalePageProductField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'CustomSeriesNum', label: this.$t('Product/List.CustomSeriesNum') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'EnableMemberAmount', label: this.$t('Product/Detail.EnableMemberAmount') },
        { key: 'Action', label: '' }
      ]
    },
    PaymentField () {
      return [
        { key: 'No', label: 'No' },
        { key: 'Name', label: this.$t('Payment.Name') },
        { key: 'System', label: this.$t('Payment.System') },
        { key: 'Method', label: this.$t('Payment.Method') },
        { key: 'Action', label: '' }
      ]
    },
    LogisticsField () {
      return [
        { key: 'No', label: 'No' },
        { key: 'Name', label: this.$t('Logistics.Name') },
        { key: 'System', label: this.$t('Logistics.System') },
        { key: 'Method', label: this.$t('Logistics.Method') },
        { key: 'Action', label: '' }
      ]
    },
    CalculateTypeOptions () {
      return Object.keys(this.$t('Product/SinglePage.CalculateTypeOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Product/SinglePage.CalculateTypeOptions')[option]
        }
      })
    },
    EnableOfferOptions () {
      return Object.keys(this.$t('Offer.Taxonomy')).map(option => {
        return {
          value: option,
          label: this.$t('Offer.Taxonomy')[option]
        }
      })
    },
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'product-single-page-detail-add' ? 'Add' : 'Edit')
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Products.SinglePage || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      console.log(err)
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Functions = [
        this.SetEnableOffer(),
        this.GetPaymentList(),
        this.GetShipmentList()
      ]
      if (this.Action === 'Add') {
        this.Data.Variable.StartTime = this.$dayjs(this.$dayjs().format('YYYY-MM-DD 00:00:00')).unix() * 1000
        this.Data.Variable.EndTime = this.$dayjs(this.$dayjs().format('YYYY-MM-DD 23:59:59')).add(1, 'months').unix() * 1000
      }
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        if (this.Action === 'Edit') this.HandleDetailData()
        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale && this.Action === 'Add') {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              Gallery: [],
              OverViewImages: [],
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
        return true
      }).catch((err) => {
        throw err
      })
    },
    Save() {
      let Action
      this.Data.Slug = this.Data.Slug.replace(/\//g, '_')
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Post/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/channel/landing/detail/' + result.data.Slug)
        }
        if (this.Action === 'Edit' && this.OldSlug !== this.Data.Slug) {
          this.$router.replace('/channel/landing/detail/' + this.Data.Slug)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Post/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.ProductSinglePage/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/add',
        method: 'post',
        data: {
          ...this.Data,
          Variable: {
            ...this.Data.Variable,
            Author: {
              Account: this.$store.state.user.data.email + `(${this.$store.state.user.data.displayName})`,
              Name: this.$store.state.user.data.displayName,
              Email: this.$store.state.user.data.email,
              Cover: this.$store.state.user.data.photoURL,
              Uid: this.$store.state.user.uid,
              Class: 'Manage'
            },
            SingleShop: true
          },
          Status: this.PostStatus ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.ProductSinglePage/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.ProductSinglePage/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/edit',
        method: 'post',
        data: {
          slug: this.OldSlug,
          updateData: {
            ...this.Data,
            Status: this.PostStatus ? 1 : 0,
            EnableResponseContent: this.EnableResponseContent ? 1 : 0
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.ProductSinglePage/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/detail',
        method: 'get',
        params: {
          slug: this.$route.params.slug
        }
      }).then(async ({data}) => {
        this.Loading = false
        this.Data = data.detail
        const AvailableProductList = await this.GetProductList()
        if (this.Data.Variable.ProductList && this.Data.Variable.ProductList.length > 0) {
          this.Data.Variable.ProductList = this.Data.Variable.ProductList.map(item => {
            const CurrentData = AvailableProductList.find(data => item._id === data._id) || {
              Status: 0
            }
            return {
              ...item,
              ...CurrentData,
              EnableMemberAmount: item.EnableMemberAmount
            }
          })
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetProductList () {
      if (!this.Data.Variable.ProductList || this.Data.Variable.ProductList.length === 0) {
        return Promise.resolve([])
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/data',
        method: 'post',
        data: {
          URL: '/product/availableList',
          Data: {
            SeriesNum: this.Data.Variable.ProductList.map(item => item.SeriesNum)
          }
        }
      }).then(({data}) => {
        return data
      }).catch((error) => {
        throw error
      })
    },
    HandleDetailData() {
      this.OldSlug = this.Data.Slug
      this.PostStatus = (this.Data.Status === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)
      this.SetEnableOffer()
        //擴充參數載入
        if (this.Data.Variable) {
            this.VariableList = Object.keys(this.Data.Variable).map((data) => {
                let Value,
                        Type = typeof this.Data.Variable[data]
                switch (typeof this.Data.Variable[data]) {
                    case 'boolean':
                    case 'string':
                    case 'undefined':
                    case 'number':
                        Value = this.Data.Variable[data]
                        break
                    case 'object':
                        Value = JSON.stringify(this.Data.Variable[data])
                        if (Array.isArray(this.Data.Variable[data])) {
                            Type = 'array'
                        }
                        break
                }
                return {
                    Index: data,
                    Value,
                    Type
                }
            })
        }

      //多語系資料處理
      if (this.$store.state.user.permission.MutipleLocale) {
        if (!this.Data.LanguageData) {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              Gallery: [],
              OverViewImages: [],
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
      }
    },
    HandleVariable() {
      this.Data.Variable = {}
      this.VariableList.forEach((data) => {
        this.Data.Variable[data.Index] = data.Value
      })
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.OtherName = this.Data.LanguageData[DefaultLocale].OtherName
        this.Data.Introduction = this.Data.LanguageData[DefaultLocale].Introduction
        this.Data.HeaderText = this.Data.LanguageData[DefaultLocale].HeaderText
        this.Data.Content = this.Data.LanguageData[DefaultLocale].Content
        this.Data.Attachment = this.Data.LanguageData[DefaultLocale].Attachment
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.Gallery = this.Data.LanguageData[DefaultLocale].Gallery
        this.Data.OverViewImages = this.Data.LanguageData[DefaultLocale].OverViewImages
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
      }
    },
    GetPaymentList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.PaymentList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.PaymentListOptions = this.PaymentList.map((item) => {
          return {
            label: item.Name,
            value: item.ID
          }
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetShipmentList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.LogisticsList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.LogisticsListOptions = this.LogisticsList.map((item) => {
          return {
            label: item.Name,
            value: item.ID
          }
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      switch (type) {
        case 'Cover':
        case 'Attachment':
          this.SetSingleImage(type)
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          this.SetMultipleImages(type)
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (this.NowChooseLanguage === 'default') {
          if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        } else {
          if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (this.NowChooseLanguage === 'default') {
            if (index) this.Data[type][index] = URL
            else this.$set(this.Data, type, URL)
          } else {
            if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = URL
            else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, URL)
          }
        })
      }
    },
    SetMultipleImages(type) {
      if (typeof this.Data[type] === 'undefined') {
        this.$set(this.Data, type, [])
      }
      this.ChooseImageType = ''
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (typeof this.MediaStoreConfig.ChooseImageList) {
        case 'string':
          if (this.$store.state.user.permission.StaticDomain) {
            if (this.NowChooseLanguage === 'default') {
              this.$set(this.Data[type], this.ChooseImageIndex, {
                URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                Alt: ''
              })
            } else {
              this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {
                URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                Alt: ''
              })
            }
          } else {
            storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
              if (this.NowChooseLanguage === 'default') {
                this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
              } else {
                this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {URL, Alt: ''})
              }
            })
          }
          break
        case 'object':
          if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
            this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
              let PushData
              if (this.$store.state.user.permission.StaticDomain) {
                PushData = {
                  URL: this.$store.state.user.permission.StaticDomain + imgPath,
                  Alt: ''
                }
                if (this.NowChooseLanguage === 'default') {
                  this.Data[type].push(PushData)
                } else {
                  this.Data.LanguageData[this.NowChooseLanguage][type].push(PushData)
                }
              } else {
                storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                  PushData = {
                    URL,
                    Alt: ''
                  }
                  if (this.NowChooseLanguage === 'default') {
                    this.Data[type].push(PushData)
                  } else {
                    this.Data.LanguageData[this.NowChooseLanguage][type].push(PushData)
                  }
                })
              }
            })
          }
          break
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.ChooseImageType = type
      switch (type) {
        case 'Carousel':
          if (index === false) {
            return false
          }
          if (this.NowChooseLanguage === 'default') {
            this.MediaMetaConfig.ImageMeta = this.Data[type][index]
          } else {
            this.MediaMetaConfig.ImageMeta = this.Data.LanguageData[this.NowChooseLanguage][type][index]
          }
          this.ChooseImageIndex = index
          break
        case 'Cover':
          if (this.NowChooseLanguage === 'default') {
            this.MediaMetaConfig.ImageMeta.URL = this.Data[type]
          } else {
            this.MediaMetaConfig.ImageMeta.URL = this.Data.LanguageData[this.NowChooseLanguage][type]
          }
          break
      }
    },
    SetMeta() {
      switch (this.ChooseImageType) {
        case 'Cover':
          this.Data.Cover = this.MediaMetaConfig.ImageMeta.URL
          break
      }
      this.MediaMetaConfig.ImageMeta = {
        URL: ''
      }
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      if (this.NowChooseLanguage === 'default') {
        this.Data[type].splice(index, 1)
      } else {
        this.Data.LanguageData[this.NowChooseLanguage][type].splice(index, 1)
      }
    },
    SearchAccount(Email) {
      this.OwnerSearchLoading = true
      this.TempAccount = Email
      setTimeout(() => {
        if (this.TempAccount === Email) {
          return this.$store.dispatch('InnerRequest', {
            url: '/user/list',
            method: 'post',
            data: {
              Email,
              Class: 'A'
            }
          }).then(({data}) => {
            this.OwnerSearchLoading = false
            this.SearchAccountData = data.list
          }).catch((err) => {
            this.OwnerSearchLoading = false
            throw err
          })
        }
      }, 1000)
    },
    AddFeePercentage(type) {
      if (!this.Data.Variable[type]) {
        this.$set(this.Data.Variable, type, [])
      }
      this.Data.Variable[type].push({
        ID: '',
        Type: 'Price',
        Value: '',
        Name: ''
      })
    },
    ChangeFeePercentage(type, index) {
      let Options
      switch (type) {
        case 'ShipmentFeePercentage':
          Options = this.LogisticsListOptions
          break
        case 'PaymentFeePercentage':
          Options = this.PaymentListOptions
          break
      }
      this.Data.Variable[type][index].Name = Options.find(item => item.value === this.Data.Variable[type][index].ID).label
      console.log(this.Data.Variable[type][index])
    },
    RemoveFeePercentage(type, index) {
      this.Data.Variable[type].splice(index, 1)
    },
    RemoveChooseProduct(index) {
      this.Data.Variable.ProductList.splice(index, 1)
    },
    OpenProductListModel() {
      this.ProductListModel = true
      this.ProductListModelComponent = () => import('@/components/product/ProductList')
    },
    OpenPaymentListModel() {
      this.ProductListModel = true
      this.ProductListModelComponent = () => import('@/components/product/PaymentList')
    },
    OpenLogisticsListModel() {
      this.ProductListModel = true
      this.ProductListModelComponent = () => import('@/components/product/LogisticsList')
    },
    SetEnableOffer () {
      if (!this.Data.Variable.EnableOffer) {
        this.$set(this.Data.Variable, 'EnableOffer', [])
      }
      return Promise.resolve()
    },
    ChooseProduct(items) {
      if (!this.Data.Variable.ProductList) {
        this.$set(this.Data.Variable, 'ProductList', [])
      }
      items.forEach(data => {
        if (!this.Data.Variable.ProductList.map(product => product._id).includes(data._id)) {
          this.Data.Variable.ProductList.push({
            _id: data._id,
            SeriesNum: data.SeriesNum,
            CustomSeriesNum: data.CustomSeriesNum,
            Name: data.Name,
            Cover: data.Cover,
            EnableMemberAmount: data.EnableMemberAmount
          })
        }
      })
      this.ProductListModel = false
    },
    ChoosePayment(items) {
      if (!this.Data.Variable.PaymentList) {
        this.$set(this.Data.Variable, 'PaymentList', [])
      }
      items.forEach(data => {
        if (!this.Data.Variable.PaymentList.map(payment => payment._id).includes(data.ID)) {
          this.Data.Variable.PaymentList.push(data)
        }
      })
      this.ProductListModel = false
    },
    ChooseLogistics(items) {
      if (!this.Data.Variable.LogisticsList) {
        this.$set(this.Data.Variable, 'LogisticsList', [])
      }
      items.forEach(data => {
        if (!this.Data.Variable.LogisticsList.map(logistics => logistics._id).includes(data.ID)) {
          this.Data.Variable.LogisticsList.push(data)
        }
      })
      this.ProductListModel = false
    },
    CopyToClipboard (string) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(string)
      }
      return Promise.reject('The Clipboard API is not available.');
    }
  }
}
</script>
